import React, { useEffect } from 'react';
import { Modal, Box, Typography, Button, List, ListItem } from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import ResultPdf from './ResultPdf';

interface ReviewModalProps {
  open: boolean;
  onClose: () => void;
  reviews: any[];
}

const ReviewModal: React.FC<ReviewModalProps> = ({ open, onClose, reviews}) => {
  const handleDownloadPdf = async (review: any) => {
    try {
      const document = <ResultPdf result={review} />;
      const blob = await pdf(document).toBlob();
      saveAs(blob, `${review.file_name_1}_審査結果_${review.task_id}_${review.model}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          maxWidth: 600,
          margin: 'auto',
          padding: 4,
          backgroundColor: '#fff',
          borderRadius: 2,
          maxHeight: '80vh',  // モーダルの最大高さ
          overflowY: 'auto',  // 縦方向にスクロールできるように設定
          marginTop: '10vh',  // 上下に少しマージンを追加
          marginBottom: '10vh',
          position: 'relative'
        }}
      >
        <Typography variant="h6" gutterBottom>審査履歴</Typography>
        <List>
          {reviews
            .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(  )) // Sort by timestamp in descending order
            .map((review, index) => (
            <ListItem key={index} sx={{ flexDirection: 'column', alignItems: 'flex-start', marginBottom: 2 }}>
              {/* タイムスタンプとファイル名を縦に並べる */}
              <Typography variant="subtitle1">
                {new Date(review.timestamp).toLocaleString()} {/* タイムスタンプ */}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {review.file_name_1} {/* 計画書ファイル名 */}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {review.file_name_2} {/* 同意書ファイル名 */}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                モデル：{review.model}
              </Typography>
              {/* <Typography>{review.task_id}</Typography> */}
              <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleDownloadPdf(review)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '8px',
                    padding: '4px 8px',
                    border: "1px solid #5F46DE",
                    borderRadius: "4px",
                    color: "#5F46DE",
                    background: '#FFF',
                    '&:hover': {
                      background: '#E5E3FA',
                    },
                    '&:active': {
                      background: '#DAD4F7'
                    }
                  }}
                >
                  PDFをダウンロード
                </Button>
            </ListItem>
          ))}
        </List>
        <Button onClick={onClose} style={{ marginTop: '16px' }}>閉じる</Button>
      </Box>
    </Modal>
  );
};

export default ReviewModal;
