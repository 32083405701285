import React, { useState } from 'react';
import { Box, Typography, Link, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import logo from '../assets/logo.svg';
import { fontFamily } from 'html2canvas/dist/types/css/property-descriptors/font-family';

const Footer: React.FC = () => {
  const [termsOpen, setTermsOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);

  const handleTermsOpen = () => {
    setTermsOpen(true);
  };

  const handleTermsClose = () => {
    setTermsOpen(false);
  };

  const handlePrivacyOpen = () => {
    setPrivacyOpen(true);
  };

  const handlePrivacyClose = () => {
    setPrivacyOpen(false);
  };

  const dialogStyle = {
    maxWidth: '95vw',
    maxHeight: '95vh',
    // fontFamily: '"YuMincho", "Hiragino Mincho ProN", "MS PMincho", serif',
  };

  return (
    <Box
      component="footer"
      sx={{
        width: '100vw',             // ビューポート全体の幅を指定
        backgroundColor: '#FFF',    // 背景色を全体に適用
      }}
    >
      <Box
        sx={{
          display: 'flex',
          margin: '0 auto',         // 中央揃え
          padding: '24px 80px',     // パディングを適用
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {/* Left side (Logo and company info) */}
        <Box sx={{ textAlign: 'left' }}>
          {/* Logo */}
          <Box sx={{ flexShrink: 0 }}>
            <img src={logo} alt="Logo" style={{ width: '85.333px', height: '24px' }} />
          </Box>
          <Typography variant='body2' gutterBottom sx={{ color: '#232323', marginTop: '16px', fontSize: '15px', fontWeight: '500' }}>
            Borzoi AI 株式会社
          </Typography>
          <Typography variant='body2' gutterBottom sx={{ color: '#232323', fontSize: '13px', fontWeight: '500' }}>
            〒101-0064<br />
            東京都千代田区神田猿楽町2-8-11 VORT水道橋Ⅲ<br /><br />
          </Typography>
          <Typography variant='body2' gutterBottom sx={{ color: '#232323', fontSize: '13px', fontWeight: '400' }}>
            ©︎Borzoi AI Inc.
          </Typography>
        </Box>

        {/* Right side (Terms and Privacy Links) */}
        <Box
          sx={{
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'row',
            gap: '40px',
            alignSelf: 'flex-end',  // 左側の4行目に合わせて、右側のリンクを下に揃える
          }}
        >
          <Link href="#" underline="none" sx={{ color: '#232323', fontSize: '13px', fontWeight: '500' }} onClick={handleTermsOpen}>
            利用規約
        </Link>
        <Link href="#" underline="none" sx={{ color: '#232323', fontSize: '13px', fontWeight: '500' }} onClick={handlePrivacyOpen}>
          プライバシーポリシー
        </Link>
      </Box>
    </Box>

    {/* Terms of Service Dialog */}
    <Dialog open={termsOpen} onClose={handleTermsClose} PaperProps={{ style: dialogStyle }}>
      <DialogTitle sx={{ textAlign: 'center', margin: 'auto', fontFamily: '"YuMincho", "Hiragino Mincho ProN", "MS PMincho", serif' }}>法人向け「AIRAS」利用規約</DialogTitle>
      <DialogContent
        sx={{ fontFamily: '"YuMincho", "Hiragino Mincho ProN", "MS PMincho", serif' }}
      >
        <Box sx={{ padding: '16px', backgroundColor: '#fff', maxWidth: '900px', margin: 'auto', lineHeight: '1.6' }}>
          <Typography variant='body1' sx={{marginBottom: '16px'}}>
            この利用規約（以下「本規約」といいます）は、Borzoi AI 株式会社（以下「当社」といいます）が提供するサービス「AIRAS」の利用に関する条件を、本サービスを利用する者と当社との間で定めるものです。
          </Typography>

          <Typography variant='body1' gutterBottom>第1条 (定義)</Typography>
          <Typography variant='body2' gutterBottom>
            本規約において用いる以下の用語の定義は以下に定めるとおりとします。
          </Typography>
          <ol style={{ marginBottom: '16px' }}>
            <li>
              <Typography variant='body2' gutterBottom>
                「本サービス」とは、当社が管理・運営する、AI を利用した文書審査サービス「AIRAS」(AI Review Assistant System)をいいます。
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                「契約者」とは、当社所定の手続きに基づいて当社から本サービスの利用を許諾された法人または団体をいいます。
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                「利用者」とは、契約者の役員もしくは従業員または契約者との間の契約により契約者の業務の一部を請け負う者で、契約者が本サービスの利用を認めた個人をいいます。
              </Typography>
            </li>
            <li>
              <Typography variant='body2' gutterBottom>
                「利用者等」とは、契約者および利用者の総称をいいます。
              </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                「審査対象文書」とは、本サービスによってその内容を審査する文書をいいます。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                「本件審査」とは、本サービスを利用して審査対象文書を審査することをいいます。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                「本件審査項目」とは、本件審査において AI-API が検証・評価する審査項目をいいます。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                「契約者情報」とは、本サービスの利用に当たって当社に提供する契約者に関する情報をいいます。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                「アカウント情報」とは、本サービスを利用する利用者を識別するためのものとして本サービスに登録される、利用者に関する当社所定の情報をいいます。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                「個人情報」とは、個人情報の保護に関する法律第2条第1項に定める個人情報をいいます。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                「AI-API」とは、本件審査のために利用する、第三者が提供する自然言語対応人工知能プログラムおよび API 等を含むその関連サービスをいいます。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                「本サーバー」とは、本サービスを運営するために利用しているすべてのサーバーをいいます。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                「本利用契約」とは、契約者からの本サービス利用の申込みを当社が承諾することによって締結される、本規約をその内容とする本サービスに関する契約をいいます。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                「本プライバシーポリシー」とは、当社が別途定めて公表する、本サービスに関する個人情報保護方針をいいます。
                </Typography>
            </li>
          </ol>

          <Typography variant='body1' gutterBottom>第2条 (規約への同意および適用)</Typography>
          <ol style={{ marginBottom: '16px' }}>
            <li>
                <Typography variant='body2' gutterBottom>
                本サービスは、本規約にご同意いただいた者だけが利用することができます。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                利用者等は、本規約の定めに従って本サービスを利用しなければならず、利用者等による本サービスの利用に関する一切の関係に本規約が適用されます。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                契約者と当社との間で別段の合意があるときは、当該合意は本規約に優先します。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                本サービスは、本件審査において AI-API を利⽤しています。これにより、本サービスの利⽤においては、当該 AI-API 提供事業者が定める利⽤規約およびプライバシーポリシーその他の規約等（以下「AI 規約等」といいます）が重畳的に適⽤されます。ただし、AI 規約等と本規約の規定が抵触する場合は、本規約の規定が優先されるものとします。
                </Typography>
            </li>
        </ol>

        <Typography variant='body1' gutterBottom>第3条 (本サービス)</Typography>
        <ol style={{ marginBottom: '16px' }}>
            <li>
                <Typography variant='body2' gutterBottom>
                本サービスは、利⽤者が本サービスに送信した審査対象⽂書を、AI-API を利⽤して本サービス所定の本件審査項⽬ごとに検証を⾏い、その結果を利⽤者に提供するものです。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                利用者は、契約者と当社との間の本利用契約が有効である場合に限り、本件審査を行うことができます。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                当社は、最善を尽くして本サービスを提供しますが、本サービスの性質に鑑み、利用者等に対して、AI-API による検証結果に関して、その正確性、有用性その他一切について保証するものではございません。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                利用者は、本利用契約が有効の間はいつでも、自己が本サービスに送信した審査対象文書のすべてを閲覧することができます。ただし、本規約に基づいて消去されたものを除きます。
                </Typography>
            </li>
            <li>
                <Typography variant='body2' gutterBottom>
                利用者は、当社所定の手続きにより、本サービスに送信した審査対象文書の全部または一部を自己の責任において消去することができます。
                </Typography>
            </li>
        </ol>

        <Typography variant='body1'>第4条 (本サービスの変更)</Typography>
        <ol style={{ marginBottom: '16px' }}>
            <li>
            <Typography variant='body2' gutterBottom>
                当社は、当社の判断に基づき、利用者等に対して事前に通知することで、本サービスの内容、機能、利用料等を随時追加、変更、または削除することができるものとします。
            </Typography>
            </li>
            <li>
            <Typography variant='body2' gutterBottom>
                前項の場合において、当該追加等によって本規約を変更する必要があると判断した場合は、当社は、第 22 条(本規約の変更)に基づいて適切に対応するものとします。
            </Typography>
            </li>
        </ol>

        <Typography variant='body1'>第5条 (本サービスの利用条件)</Typography>
        <ol style={{ marginBottom: '16px' }}>
          <li>
            <Typography variant='body2' gutterBottom>
                本サービスの利用料、契約期間、契約期間における本件審査の上限数その他の本サービスの利用にかかる条件は、事前に契約者と当社との間で協議の上で定めるものとします。
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                本サービスの利用にかかる条件は、契約者と当社との協議に基づく合意により変更することができるものとします。
            </Typography>
          </li>
        </ol>
        <Typography variant='body1'>第6条 (費用負担)</Typography>
        <ol style={{ marginBottom: '16px' }}>
          <li>
            <Typography variant='body2' gutterBottom>
                当社は、契約者と当社との間で別段の合意がある場合を除き、いかなる理由においても支払い済みの利用料に関して返金いたしません。
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                利用者等は、自己の責任と費用負担において、通信端末、通信回線その他の本サービスを利用するための機器等を確保、維持するものとします。
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                本サービスの利用において必要となる通信費用は、利用者等の負担とします。
            </Typography>
          </li>
        </ol>
        <Typography variant='body1'>第7条 (契約者の義務)</Typography>
        <ol style={{ marginBottom: '16px' }}>
          <li>
            <Typography variant='body2' gutterBottom>
                契約者は、利用者による本サービスの利用に関する一切について責任を負うものとします。
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                契約者は、利用者に適用される本規約の規定に違反しないよう、適切に指導監督し、または別段の契約等により利用者に適用される本規約の規定と同等の義務を課す責任を負うものとします。
            </Typography>
          </li>
        </ol>
        <Typography variant='body1'>第8条 (契約者情報等)</Typography>
        <ol style={{ marginBottom: '16px' }}>
          <li>
            <Typography variant='body2' gutterBottom>
                契約者は、当社に開示した自己に関する情報に変更が生じたときは、遅滞なく当社にその旨を届け出るものとし、常に最新の情報となるよう努めるものとします。
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                利用者は、本サービスに登録されている自己に関する情報に変更が生じたときは、遅滞なく本サービスより当該情報を変更するものとし、常に最新の情報となるよう努めるものとします。
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                当社は、利用者等が前各項の変更を怠ったことにより利用者等に生じた損害その他不利益について、一切の責任を負いません。
            </Typography>
          </li>
        </ol>
        <Typography variant='body1'>第9条 (アカウント情報)</Typography>
        <ol style={{ marginBottom: '16px' }}>
          <li>
            <Typography variant='body2' gutterBottom>
                利用者等は、利用者のアカウント情報を自己の責任において適切に管理するものとし、第三者と共有もしくは利用させ、または譲渡もしくは貸与することはできません。
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                利用者等は、利用者のアカウント情報が漏えいし、または第三者が利用しているおそれがあることを認めたときは、直ちに当社に通知し、当社の指示に従って対応するものとします。
            </Typography>
          </li>
        </ol>
        <Typography variant='body1'>第10条 (当社による情報の取扱い)</Typography>
        <ol style={{ marginBottom: '16px' }}>
          <li>
            <Typography variant='body2' gutterBottom>
                当社は、利用者が送信した審査対象文書に関して、AI-API に関する AI モデルの学習のための利用を含む一切の利用を行わず、また機密性・秘匿性の高い文書として適切に管理・保存するものとします。
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                当社は、本サービスに送信されてから10年を経過した審査対象文書について、消去することができるものとします。
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                当社は、本サービスの提供において当社が取得する利用者または第三者の個人情報について、本プライバシーポリシーに従って適切に取り扱います。
            </Typography>
          </li>
        </ol>
        <Typography variant='body1'>第11条 (知的財産権)</Typography>
        <ol style={{ marginBottom: '16px' }}>
          <li>
            <Typography variant='body2' gutterBottom>
                利用者が本サービスに送信した審査対象文書にかかる一切の商標権、意匠権、著作権(著作権法第27条および28条の権利を含みます)その他の知的財産権(以下総称して「本知的財産権」といいます)は、利用者または利用者に利用を許諾した者に帰属するものとし、当社は何らの本知的財産権を取得しません。
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
            審査対象文書を除く、本サービス内に掲載されるすべての画像、文章、映像その他のコンテンツにかかる一切の知的財産権および本サービスのプログラム、設計その他の権利は、当社または当社による利用を許諾した権利者に帰属しており、本規約または当社による同意によって利用者による利用が明示的に許諾されている場合を除き、本利用契約によって利用者に利用が許諾されるものではありません。
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                当社は、本サーバーのメンテナンスまたはバックアップその他の理由により必要があると判断したときは、本サービスに送信された審査対象文書の全部または一部を複製することができるものとします。
            </Typography>
          </li>
        </ol>
        <Typography variant='body1'>第12条 (禁止事項)</Typography>
        <Typography variant='body2' gutterBottom>利用者等は、本サービスの利用において、以下の各号の行為をしてはなりません。</Typography>
        <ol style={{ marginBottom: '16px' }}>
          <li>
            <Typography variant='body2' gutterBottom>
                法令または本規約に違反する行為
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                故意または重過失により虚偽の内容または意思表示を本サービスまたは当社に送信、提供、提示する行為
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                本件審査項目(自己が追加したものを除きます)の全部または一部を第三者に開示し、または本サービス外で利用する行為
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                第三者になりすます行為
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                再販売その他本サービスを第三者に利用させる行為
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                第 9 条(アカウント情報)第1項に違反する行為
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>当社を誹謗中傷する行為</Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                本サーバーに対する不正アクセス行為およびそれを試みる行為
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                データの大量送信、コンピューターウイルス等の送信その他の本サービスの運営を妨げ、または本サーバーに過剰な負荷をかける行為
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>悪意をもって本サービスの誤動作を利用する行為</Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                当社または第三者の保有する著作権、商標権、意匠権その他の知的財産権もしくは肖像権その他の権利を侵害し、または侵害を誘発する行為
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>当社に対する暴力、暴言、恐喝、脅迫、セクハラその他ハラスメント行為およびこれらに準ずる行為</Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>当社に対する法的な責任を超えた不当な要求行為</Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                風説を流布し、偽計または威力を用いて当社の信用を棄損し、または当社の業務を妨害する行為
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>反社会的勢力を利用し、または反社会的勢力に利益を供与する行為</Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>前四号に準ずる行為</Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
                前各号のほか、当社が合理的な根拠に基づいて、本サービスを利用する者として不適切であると合理的に判断する行為
            </Typography>
          </li>
        </ol>
        <Typography variant='body1'>第13条 (本サービスの停止・中断)</Typography>
        <Typography variant='body2' gutterBottom>
            当社は、以下の各号のいずれかに該当する場合は、利用者等に対して事前に通知することなく、本サービスの全部または一部の提供を停止し、または中断することがあります。
        </Typography>
        <ol style={{ marginBottom: '16px' }}>
            <li>
            <Typography variant='body2' gutterBottom>
                本サービスのシステム、本サーバー、通信回線、通信機器等または第三者が提供するサービスもしくは機器であって当社が本サービスの運営のために利用しているもの(以下「本システム等」といいます)の点検もしくは保守のために必要である場 合
            </Typography>
            </li>
            <li>
            <Typography variant='body2' gutterBottom>
                本システム等で発生した障害によって本サービスを正常に運営することができなくなった場合
            </Typography>
            </li>
            <li>
            <Typography variant='body2' gutterBottom>
                地震、風水害その他の災害、停電、感染症のまん延その他の事情により、本サービスを正常に運営することが不能または著しく困難となった場合
            </Typography>
            </li>
            <li>
            <Typography variant='body2' gutterBottom>
                前各号の他、当社において本サービスの全部もしくは一部の提供を停止し、または中断すべき事情が生じた場合
            </Typography>
            </li>
        </ol>
        <Typography variant='body1'>第14条 (サービスの廃止)</Typography>
        <ol style={{ marginBottom: '16px' }}>
            <li>
            <Typography variant='body2' gutterBottom>
                当社は、契約者に対する1か月以上前の通知により、本サービスの全部を廃止することができるものとします。
            </Typography>
            </li>
            <li>
            <Typography variant='body2' gutterBottom>
                当社が本サービスの全部を廃止したときは、当該廃止時点をもって、当社と契約者との間の本利用契約が解除されるものとします。
            </Typography>
            </li>
        </ol>
        <Typography variant='body1'>第15条 (中途解約)</Typography>
        <ol style={{ marginBottom: '16px' }}>
            <li>
            <Typography variant='body2' gutterBottom>
                契約者は、本サービスの利用を終了するときは、当社所定の方法により当社に申し出るも のとします。
            </Typography>
            </li>
            <li>
            <Typography variant='body2' gutterBottom>
                当社は、前項の申出を受けたときは、遅滞なく契約者との協議により契約終了日について合意するものとし、当該期日をもって本サービスの利用が終了するものとします。当該終了と同時に、当該契約者との間の本利用契約も解除されます。
            </Typography>
            </li>
        </ol>
        <Typography variant='body1'>第16条 (当社による利用停止および解除)</Typography>
        <ol style={{ marginBottom: '16px' }}>
            <li>
            <Typography variant='body2' gutterBottom>
                当社は、契約者が以下の各号のいずれかに該当すると判断したときは、当該契約者への事前の催告なく、当該契約者に対する本サービスの全部または一部の提供を停止し、または当該停止にかかる本利用契約を解除することができます。
            </Typography>
            <ol>
                <li>
                <Typography variant='body2' gutterBottom>本サービスの利用料の決済が不能となったとき</Typography>
                </li>
                <li>
                <Typography variant='body2' gutterBottom>監督官庁による営業許可取消し、停止その他行政処分があったとき</Typography>
                </li>
                <li>
                <Typography variant='body2' gutterBottom>
                    破産手続開始、再生手続開始、更生手続開始または特別清算開始の申立てがあったとき
                </Typography>
                </li>
                <li>
                <Typography variant='body2' gutterBottom>仮差押え、仮処分、強制執行または競売の申立てがあったとき</Typography>
                </li>
                <li>
                <Typography variant='body2' gutterBottom>
                    解散、会社分割、事業譲渡または合併の決議をしたとき
                </Typography>
                </li>
                <li>
                <Typography variant='body2' gutterBottom>
                    法令、条例等に違反することにより、当社または他の利用者等に損害を与えた、または与えるおそれがあるとき
                </Typography>
                </li>
                <li>
                <Typography variant='body2' gutterBottom>
                    第 12 条(禁止事項)に違反したとき
                </Typography>
                </li>
                <li>
                <Typography variant='body2' gutterBottom>
                    本規約のいずれかの条項(第 12 条を除きます)に違反した場合であって、当社が是正を催告してから14日を経過しても契約者がこれを是正しないとき
                </Typography>
                </li>
            </ol>
            </li>
            <li>
            <Typography variant='body2' gutterBottom>
                当社は、利用者が前項第6号から第8号のいずれかに該当すると判断したときは、当該利用者による本サービスの全部または一部の提供を停止することができるものとしま す。
            </Typography>
            </li>
            <li>
            <Typography variant='body2' gutterBottom>
                前各項に基づく当社の措置は、当社から利用者等に対する損害賠償請求を妨げません。
            </Typography>
            </li>
        </ol>
        <Typography variant='body1'>第17条 (本利用契約終了後の処理)</Typography>
        <ol style={{ marginBottom: '16px' }}>
            <li>
            <Typography variant='body2' gutterBottom>
                当社は、前三条の規定により契約者と当社との間の本利用契約が解除されたときは、当該契約者に関する情報(当該契約者によって利用が認められた利用者が本サービスに送信した審査対象文書を含みます)の全部または一部を消去することができるものとします。なお、本項の規定は、当社が消去する義務を負うものではありません。
            </Typography>
            </li>
            <li>
            <Typography variant='body2' gutterBottom>
                前項に規定するときにおいても、第 11 条(知的財産権)、第 19 条(免責)、第 20 条(損害賠償)、第 21 条(権利義務の譲渡)、第 24 条(分離可能性)、第 25 条(準拠法および裁判管轄)および本条の規定は有効に存続するものとします。
            </Typography>
            </li>
        </ol>
        <Typography variant='body1'>第18条 (通知)</Typography>
        <ol style={{ marginBottom: '16px' }}>
            <li>
            <Typography variant='body2' gutterBottom>
                利用者等は、当社から事前の指示がある場合を除き、当社への連絡は本サービスを通じて、または当社が別途指定する方法によって行うものとします。
            </Typography>
            </li>
            <li>
            <Typography variant='body2' gutterBottom>
                当社は、利用者等への通知の必要があると判断したときは、本サービス内での掲示または契約者の担当者のものとして開示されたメールアドレス宛てへのメール送信その他通知内容を勘案し適切であると判断した方法にて通知を行います。
            </Typography>
            </li>
            <li>
            <Typography variant='body2' gutterBottom>
                前項所定の当社からの通知は、当社が当該通知を発した時点をもって利用者等に到達したものとみなします。
            </Typography>
            </li>
        </ol>
        <Typography variant='body1'>第19条 (免責)</Typography>
        <ol style={{ marginBottom: '16px' }}>
            <li>
            <Typography variant='body2' gutterBottom>
                当社は、本サービスまたは本サービスを通じて提供される情報その他本サービスにより利用者等が取得し得る一切の情報が、利用者等の特定の目的に適合すること、期待する機能、利益、結果、完全性および利用価値を有すること、本サービスの利用が利用者等に適用のある法令に適合すること、継続的に利用できること、ならびに不具合が生じないことについて、何ら保証するものではございません。
            </Typography>
            </li>
            <li>
            <Typography variant='body2' gutterBottom>
                当社は、本サービスにおける操作ミスその他の利用者等の過失に起因し、または本規約に基づく当社もしくは本サービス上の措置により利用者等に生じた損害について、一切の責任を負いません。
            </Typography>
            </li>
            <li>
            <Typography variant='body2' gutterBottom>
                本サーバー、本サービスに関するシステムおよびサービス(AI-API を含みます)ならびに通信回線その他第三者が提供するサービスもしくは機器等、または不可抗力その他当社の責めに帰することができない事由に起因する本サービスの停止、本利用契約の履行不能、不完全履行または履行遅滞について、当社は一切の責任を負いません。
            </Typography>
            </li>
            <li>
            <Typography variant='body2' gutterBottom>
                前各項の規定は、利用者等に生じた損害に関して、当社の故意または重大な過失がある場 合は適用しません。
            </Typography>
            </li>
        </ol>
        <Typography variant='body1'>第20条 (損害賠償)</Typography>
        <Typography variant='body2' gutterBottom>
            利用者等は、本規約に違反する行為に起因して当社または第三者に生じた損害について、賠償する責任を負うものとします。
        </Typography>
        <Typography variant='body1'>第21条 (権利義務の譲渡)</Typography>
        <ol style={{ marginBottom: '16px' }}>
            <li>
            <Typography variant='body2' gutterBottom>
                利用者等は、本利用契約について、その契約上の地位およびこれにより生じる権利義務の全部または一部を、第三者に譲渡または貸与することはできません。
            </Typography>
            </li>
            <li>
            <Typography variant='body2' gutterBottom>
                当社は、本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い本規約上の地位、本利用契約に基づく権利および義務ならびに利用者情報その他の本サービス利用にかかる利用者に関する情報を当該事業譲渡の譲受人に譲渡することができるものとし、利用者は、かかる譲渡につき本項においてあらかじめ同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
            </Typography>
            </li>
        </ol>
        <Typography variant='body1'>第22条 (本規約の変更)</Typography>
        <Typography variant='body2' gutterBottom>
            当社は、必要と判断した場合にはいつでも、本サービス上での通知その他合理的な方法によって変更後の規約の内容および施行時期を事前に契約者に通知することで、本規約を変更することができるものとします。ただし、法令の規定により、契約者の同意が必要である内容への変更の場合は、当社所定の方法により利用者の同意を得るものとします。
        </Typography>
        <Typography variant='body1'>第23条 (協議)</Typography>
        <Typography variant='body2' gutterBottom style={{ marginBottom: '16px' }}>
            本規約の解釈について異議、疑義が生じた場合、または本規約に定めのない事項が生じた場合は、契約者等および当社は誠実に協議し、円満にその解決を図るものとします。
        </Typography>
        <Typography variant='body1'>第24条 (分離可能性)</Typography>
        <Typography variant='body2' gutterBottom style={{ marginBottom: '16px' }}>
            本規約のいずれかの条項またはその一部が、法令により無効または執行不能と判断された場合においても、本規約のそれ以外の条項および部分については、なお完全に効力を有するものとします。
        </Typography>
        <Typography variant='body1'>第25条 (準拠法および裁判管轄)</Typography>
        <ol style={{ marginBottom: '16px' }}>
          <li>
            <Typography variant='body2' gutterBottom>
              本規約は、日本法に準拠し、解釈されるものとします。
            </Typography>
          </li>
          <li>
            <Typography variant='body2' gutterBottom>
              本サービスまたは本規約に関して契約者等と当社との間で紛争(裁判所の調停手続を含みます)が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </Typography>
          </li>
        </ol>
        <Typography variant='body1' gutterBottom>制定:2024 年 10 月 8 日</Typography>
        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTermsClose} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>

        {/* Privacy Policy Dialog */}
        <Dialog open={privacyOpen} onClose={handlePrivacyClose} PaperProps={{ style: dialogStyle }}>
        <DialogTitle sx={{ textAlign: 'center', margin: 'auto', fontFamily: '"YuMincho", "Hiragino Mincho ProN", "MS PMincho", serif' }}>プライバシーポリシー</DialogTitle>
        <DialogContent sx={{fontFamily: '"YuMincho", "Hiragino Mincho ProN", "MS PMincho", serif'}}>
          <Box
            sx={{
              padding: '16px',
              backgroundColor: '#fff',
              maxWidth: '900px',
              margin: 'auto',
              lineHeight: '1.6',
            }}
          >
          <Typography variant='body1'>
            Borzoi AI 株式会社(以下「当社」といいます)は、当社が提供する、AI を利用した文書審査サービス 「AIRAS」(以下「本サービス」といいます)のご利用に関して、本サービスの利用者から取得する個人情報その他の本サービスを通じて取得する情報の取扱いについて、以下のとおりプライバシーポリシー(以下「本ポリシー」といいます)を定めます。
          </Typography>
          <Typography variant='body1'>
            なお、本ポリシーにおいて使用する用語は、別段の記載がある場合を除き、個人情報保護法に準拠するもの とします。
          </Typography>

          <ol>
            <li style={{ marginBottom: '16px' }}>
              <Typography variant='body1'>
                法令およびガイドライン等の遵守
              </Typography>
              <Typography variant='body2' gutterBottom>
                当社は、個人情報保護法その他の関係法令、個人情報保護委員会の定めるガイドライン等および本ポリシーを遵守し、利用者の個人情報を適法かつ適正に取扱います。
              </Typography>
            </li>
            <li style={{ marginBottom: '16px' }}>
              <Typography variant='body1'>取得する情報の項目</Typography>
              <Typography variant='body2' gutterBottom>
                当社は、利用者による本サービスのご利用において、利用者に関する以下の情報(個人情報を含み、以下「個人情報等」といいます)を取得する場合がございます。
              </Typography>
              <ol>
                <li>
                  <Typography variant='body2' gutterBottom>
                    本サービスのご利用またはご利用のお申し込みに関して利用者ご本人から取得する情報
                  </Typography>
                  <Typography variant='body2' gutterBottom>
                    氏名、メールアドレス、所属組織名、本サービスを利用して審査を行う文書(以下「審査対象文書」といいます)その他本サービスを通じて本サービスに送信する情報
                  </Typography>
                </li>
                <li>
                  <Typography variant='body2' gutterBottom>
                    当社へのお問い合わせにおいて取得する情報
                  </Typography>
                  <Typography variant='body2' gutterBottom>
                    当社へのお問い合わせやご連絡において送信された内容に含まれる情報
                  </Typography>
                </li>
              </ol>
            </li>
            <li style={{ marginBottom: '16px' }}>
              <Typography variant='body1'>
                個人情報等の利用目的
              </Typography>
              <Typography variant='body2' gutterBottom>
                当社は、取得した個人情報等を、以下の利用目的の範囲内で利用いたします。
              </Typography>
              <ol>
                <li>
                  <Typography variant='body2' gutterBottom>
                    本サービスにかかるサービスの提供(AI による審査を含みます)のため
                  </Typography>
                </li>
                <li>
                  <Typography variant='body2' gutterBottom>
                    本サービスへの登録の受付、利用者の認証その他の利用者管理のため
                  </Typography>
                </li>
                <li>
                  <Typography variant='body2' gutterBottom>
                    本サービスの利用料の決済に関する処理のため
                  </Typography>
                </li>
                <li>
                  <Typography variant='body2' gutterBottom>
                    利用者に対する通知のため
                  </Typography>
                </li>
                <li>
                  <Typography variant='body2' gutterBottom>
                    当社への問い合わせに対応するため
                  </Typography>
                </li>
                <li>
                  <Typography variant='body2' gutterBottom>
                    本サービスの改善及び新サービスの企画、研究、開発のため
                  </Typography>
                </li>
                <li>
                  <Typography variant='body2' gutterBottom>
                    不正行為等の検知、調査、防止及び対応のため
                  </Typography>
                </li>
              </ol>
            </li>
            <li style={{ marginBottom: '16px' }}>
              <Typography variant='body1'>個人情報等の消去・削除</Typography>
              <Typography variant='body2' gutterBottom>
                当社は、当社が取得した個人情報等につきまして、当社が取得した日から10年を経過したとき、または「8. 個人情報等の開示について」に基づいて利用者から請求を受けたときに消去または削除いたします。 消去された個人情報等につきましては、後日利用者から開示、変更等の申出を受けたとしても対応することができませんので、予めご了承ください。
              </Typography>
            </li>
            <li style={{ marginBottom: '16px' }}>
              <Typography variant='body1'>第三者提供</Typography>
              <Typography variant='body2' gutterBottom>
                当社は、利用者ご本人の同意がある場合、または個人情報保護法その他法令の定めにより認められる場合を除き、取得した個人情報等を第三者に開示することはございません。
              </Typography>
            </li>
            <li style={{ marginBottom: '16px' }}>
              <Typography variant='body1'>外国にある第三者サービスの利用</Typography>
              <Typography variant='body2' gutterBottom>本サービスでは、サービスの提供および決済処理の一部のために、外国にある外部の事業者が提供するサービスを利用しています。</Typography>
              <Typography variant='body2' gutterBottom>これにより、お客様の情報の一部が、これらの外部事業者に送信される場合があり、それぞれの事業者が定めるプライバシーポリシー等に従って管理・利用されます。</Typography>
              <Typography variant='body2' gutterBottom>(外部送信先)</Typography>
              <ul>
                <li>
                  <Typography variant='body2' gutterBottom>OpenAI</Typography>
                  <Typography variant='body2' gutterBottom>[外部事業者名(外国事業者)] Open AI, Inc.</Typography>
                  <Typography variant='body2' gutterBottom>[送信される情報] 本サービスに送信された審査対象文書</Typography>
                  <Typography variant='body2' gutterBottom>[本サービスにおける利用目的] 送信された文書を AI を用いて審査するため(AI モデルの学習には利用さ れません)</Typography>
                  <Typography variant='body2' gutterBottom>[プライバシーポリシー] https://openai.com/ja/policies/privacy-policy</Typography>
                </li>
                <li>
                  <Typography variant='body2' gutterBottom>Stripe</Typography>
                  <Typography variant='body2' gutterBottom>[外部事業者名(外国事業者)] Stripe, Inc.</Typography>
                  <Typography variant='body2' gutterBottom>[送信される情報] 個人契約の利用者が指定した自己のクレジットカード情報</Typography>
                  <Typography variant='body2' gutterBottom>[本サービスにおける利用目的] 本サービスの利用料決済に関する処理のため</Typography>
                  <Typography variant='body2' gutterBottom>[プライバシーポリシー] https://stripe.com/jp/privacy</Typography>
                </li>
                <li>
                  <Typography variant='body2' gutterBottom>上記外国事業者の所在国:アメリカ合衆国</Typography>
                </li>
                <li>
                  <Typography variant='body2' gutterBottom>外国事業者の所在国における個人情報の保護に関する制度に関する情報:</Typography>
                  <Typography variant='body2' gutterBottom>https://www.ppc.go.jp/files/pdf/USA_report.pdf (アメリカ合衆国(連邦))</Typography>
                  <Typography variant='body2' gutterBottom>https://www.ppc.go.jp/files/pdf/california_report.pdf (カリフォルニア州)</Typography>
                </li>
              </ul>
            </li>
            <li style={{ marginBottom: '16px' }}>
              <Typography variant='body1'>安全管理措置</Typography>
              <Typography variant='body2' gutterBottom>
                当社は、審査対象文書をはじめとする本サービスに送信される情報が機密情報であり、その秘匿性が高いことを十分に鑑み、これらの文書を含む個人情報等の漏えい、滅失又は毀損の防止その他個人情報等の安全管理のために、以下の措置を含めた必要かつ適切な措置を講じます。
              </Typography>
              <ul>
                <li>
                  <Typography variant='body2' gutterBottom>
                    個人情報等にアクセスできる権限の明確化と厳格な認証
                  </Typography>
                </li>
                <li>
                  <Typography variant='body2' gutterBottom>
                    個人情報等が保存(一時的なものを含みます)される端末への適切なセキュリティ設定
                  </Typography>
                </li>
              </ul>
              <Typography variant='body2' gutterBottom>詳しくは下記「10.個人情報に関するお問い合わせ窓口」までお問い合わせください。</Typography>
            </li>
            <li style={{ marginBottom: '16px' }}>
              <Typography variant='body1'>個人情報等の開示について</Typography>
              <Typography variant='body2' gutterBottom>
                当社は、個人情報保護法の規定に従って、ご本人から自己の個人情報等の開示等(利用目的の通知、開示、内容の訂正・追加・削除、利用の停止、第三者への提供の停止)の請求を受けたときは、当社所定の本人確認をさせていただいたうえで、合理的な期間内に対応いたします。開示等の請求の詳細につきましては、下記の「10.個人情報に関するお問い合わせ窓口」までお問い合わせください。
              </Typography>
              <Typography variant='body2' gutterBottom>
                なお、法令の規定により、開示等の請求に対応しない場合もございますので、あらかじめご了承ください。
              </Typography>
            </li>
            <li style={{ marginBottom: '16px' }}>
              <Typography variant='body1'>本ポリシーの変更</Typography>
              <Typography variant='body2' gutterBottom>当社は、必要があると判断したときは、本ポリシーを変更する場合がございます。この場合には、本サービス内での掲載その他適切な方法により新しいポリシー及び施行時期を公表します。</Typography>
              <Typography variant='body2' gutterBottom>なお、法令の規定に基づき、利用者本人の同意が必要であると判断したときは、当社は適切な方法により同意を得るようにいたします。</Typography>
            </li>
            <li style={{ marginBottom: '16px' }}>
              <Typography variant='body1'>個人情報等に関するお問い合わせ窓口</Typography>
              <Typography variant='body2' gutterBottom>本ポリシーに関するご意見、ご質問、苦情のお申出、個人情報等の開示等の請求その他当社による個人情報等の取り扱いに関するお問い合わせは、下記窓口までメールにてご連絡ください。</Typography>
            </li>
          </ol>

          <Box sx={{ marginY: '16px' }}>
            <Typography variant='body2' gutterBottom>(お問い合わせ窓口)</Typography>
            <Typography variant='body2' gutterBottom>contact@borzoi.ai</Typography>
          </Box>
          <Box sx={{ marginY: '16px' }}>
            <Typography variant='body2' gutterBottom>(個人情報取扱事業者)</Typography>
            <Typography variant='body2' gutterBottom>Borzoi AI 株式会社</Typography>
            <Typography variant='body2' gutterBottom>東京都千代田区神田猿楽町2−8−11 VORT 水道橋III</Typography>
            <Typography variant='body2' gutterBottom>代表取締役 原 宏太</Typography>
          </Box>

          <Typography variant='body2' gutterBottom>制定:2024 年 10 月 8 日</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePrivacyClose} color="primary">
          閉じる
        </Button>
       </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Footer;
