import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useAuth0 } from '@auth0/auth0-react';
import { PersonAddAlt1 } from '@mui/icons-material';
import { UserGroup } from '../types';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import HistoryIcon from '@mui/icons-material/History';  // 審査履歴
import DomainIcon from '@mui/icons-material/Domain';  // 組織


interface DashboardPanelProps {
    onReviewHistory: () => void;
    onGroupUsers: () => void;
    onLogout: () => void;
}

const DashboardPanel: React.FC<DashboardPanelProps> = ({
    onReviewHistory,
    onGroupUsers,
    onLogout
}) => {
    const { user } = useAuth0();

    return (
        <Box
            sx={{
                position: 'absolute',
                top: '48px',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '20px',
                padding: '32px',
                borderRadius: '6px',
                border: '1px solid #DAD4F7',
                backgroundColor: '#E5E3FA',
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
                zIndex: 1,
                minWidth: '220px',             // パネルの最低幅を設定
            }}
        >
            {/* ユーザー情報 */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    backgroundColor: '#FFFFFF',
                    padding: '16px',
                    borderRadius: '4px',
                    gap: '8px',
                    margin: '0 auto',
                    width: '100%',
                    maxWidth: '188px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                    }}
                >
                    <PersonIcon sx={{ color: '#000000' }} />
                    <Typography variant="body1" sx={{ color: '#232323', fontSize: '14px', fontWeight: 500, lineHeight: '19.6px' }}>
                        {user?.email}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                    }}
                >
                    <DomainIcon sx={{ color: '#000000' }} />
                    <Typography variant="body1" sx={{ color: '#232323', fontSize: '14px', fontWeight: 500, lineHeight: '19.6px' }}>
                        {user?.org_display_name}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap:'4px'
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        color: '#232323',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '16.8px'
                    }}
                >
                    次回更新日:
                </Typography>
                <Typography sx={{ color: '#5135DC', fontSize: '16px', fontWeight: 500, lineHeight: '22.4px' }}>
                    {user?.org_contract_date}
                </Typography>
            </Box>

            {/* 審査履歴ボタン */}
            <Button
                onClick={onReviewHistory}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    color: '#232323',
                    flexDirection: 'row',  // アイコンとテキストを横並びにする
                    whiteSpace: 'nowrap',  // テキストの改行を防ぐ
                }}
            >
                <HistoryIcon />
                <Typography
                    variant="body1"
                    sx={{
                        color: '#232323',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '19.6px',  // 指定されたline-height
                    }}
                >
                    審査履歴
                </Typography>
            </Button>

            <Divider sx={{ alignSelf: 'stretch', height: 0, borderColor: '#C2B8F2', borderWidth: '1px' }} />

            {/* グループユーザーリストボタン */}
            <Button
                onClick={onGroupUsers}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    color: '#232323',
                    flexDirection: 'row',  // アイコンとテキストを横並びにする
                    whiteSpace: 'nowrap',  // テキストの改行を防ぐ
                }}
            >
                <GroupIcon />
                <Typography
                    variant="body1"
                    sx={{
                        color: '#232323',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '19.6px',  // 指定されたline-height
                    }}
                >
                    グループユーザーリスト
                </Typography>
            </Button>

            <Divider sx={{ alignSelf: 'stretch', height: 0, borderColor: '#C2B8F2', borderWidth: '1px' }} />

            {/* ログアウトボタン */}
            <Button
                onClick={onLogout}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    color: '#232323',
                    flexDirection: 'row',  // アイコンとテキストを横並びにする
                    whiteSpace: 'nowrap',  // テキストの改行を防ぐ
                }}
            >
                <LogoutIcon />
                <Typography
                    variant="body1"
                    sx={{
                        color: '#232323',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '19.6px',  // 指定されたline-height
                    }}
                >
                    ログアウト
                </Typography>
            </Button>
        </Box>
    );
};

export default DashboardPanel;
