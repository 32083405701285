import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Menu, SelectChangeEvent, Accordion, Button, FormControl, FormControlLabel, Select, MenuItem, AccordionSummary, AccordionDetails, TextField, Box, List, ListItem, ListItemText, IconButton, Typography, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, Check as CheckIcon, ExpandMore } from '@mui/icons-material';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { db, auth } from '../firebase';
import { doc, collection, getDocs, addDoc, updateDoc, deleteDoc, getDoc, setDoc } from 'firebase/firestore';
import { signInWithCustomToken } from 'firebase/auth';
import listIcon from '../assets/listIcon.svg';
import expandIcon from '../assets/expandIcon.svg';
import AddIcon from '../assets/addIcon';
// import addCircleIcon from '../assets/addCircleIcon.svg';
import AddCircleButton from './AddCircleIcon';

interface QuestionListProps {
    questions: string[];
    newQuestion: string;
    editingIndex: number | null;
    onAddQuestion: () => void;
    onDeleteQuestion: (index: number) => void;
    onEditQuestion: (index: number) => void;
    onSaveQuestion: (index: number) => void;
    onQuestionChange: (index: number, value: string) => void;
    onNewQuestionChange: (value: string) => void;
    setQuestions: (questions: string[]) => void;
}

const QuestionList: React.FC<QuestionListProps> = ({
    questions,
    newQuestion,
    editingIndex,
    onAddQuestion,
    onDeleteQuestion,
    onEditQuestion,
    onSaveQuestion,
    onQuestionChange,
    onNewQuestionChange,
    setQuestions,
}) => {
    const [questionLists, setQuestionLists] = useState<{ id: string; name: string; questions: string[] }[]>([]);
    const [selectedListId, setSelectedListId] = useState<string | null>(null);
    const [selectedListName, setSelectedListName] = useState<string>('');
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [newListName, setNewListName] = useState<string>('');
    const [newListDialogOpen, setNewListDialogOpen] = useState<boolean>(false);
    const [dialogAction, setDialogAction] = useState<() => void>(() => { });
    const [defaultLists, setDefaultLists] = useState<{ id: string; name: string }[]>([]);
    const [selectedDefaultLists, setSelectedDefaultLists] = useState<string[]>([]);
    const [defaultDownloadDialogOpen, setDefaultDownloadDialogOpen] = useState<boolean>(false);
    const [defaultPromptDialogOpen, setDefaultPromptDialogOpen] = useState<boolean>(false);
    const [doNotShowAgain, setDoNotShowAgain] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { getAccessTokenSilently, user, isAuthenticated, isLoading } = useAuth0(); // Use Auth0's user and authentication state

    const inputRef = useRef<HTMLInputElement>(null);

    const saveUserSettings = async (userId: string, settings: { [key: string]: any }) => {
        try {
            await setDoc(doc(db, `users/${userId}/settings/dialog`), settings, { merge: true });
        } catch (error) {
            console.error('ユーザー設定の保存に失敗しました', error);
        }
    };

    const handleOpenListChoice = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseListChoice = () => {
        setAnchorEl(null);
    }

    const loadUserSettings = async (userId: string) => {
        try {
            const settingsDoc = await getDoc(doc(db, `users/${userId}/settings/dialog`));
            if (settingsDoc.exists()) {
                const data = settingsDoc.data();
                if (data && data.hasOwnProperty('showDefaultPrompt')) {
                    return data;
                } else {
                    await setDoc(doc(db, `users/${userId}/settings/dialog`), { showDefaultPrompt: true }, { merge: true });
                    return { showDefaultPrompt: true };
                }
            } else {
                await setDoc(doc(db, `users/${userId}/settings/dialog`), { showDefaultPrompt: true }, { merge: true });
                return { showDefaultPrompt: true };
            }
        } catch (error) {
            console.error('ユーザー設定の読み込みに失敗しました', error);
            return { showDefaultPrompt: true };
        }
    };


    const handleDownloadDefaultListsIfNeeded = useCallback(async () => {
        const settings = await loadUserSettings(user?.sub || '');
        if (!settings.showDefaultPrompt) {
            return;
        }
        setDefaultPromptDialogOpen(true);
    }, []);

    const handleDeleteList = (id: string) => {

        setDialogAction(() => async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/get_firebase_token`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,  // Authorizationヘッダーにトークンを追加
                    }
                });
                const firebaseToken = response.data.firebase_token;
                await signInWithCustomToken(auth, firebaseToken);
                const currentUser = auth.currentUser;
                const userId = currentUser?.uid;
                // Use user.sub (Auth0 user identifier) instead of Firebase UID
                await deleteDoc(doc(db, `users/${userId}/questionLists/${id}`));
                const updatedLists = questionLists.filter(list => list.id !== id);
                setQuestionLists(updatedLists);

                if (updatedLists.length > 0) {
                    // リストがまだ存在する場合、最初のリストを選択
                    const firstList = updatedLists[0];
                    setSelectedListId(firstList.id);
                    setSelectedListName(firstList.name);
                    setQuestions(firstList.questions || []);
                } else {
                    // リストがなくなった場合、選択状態をクリア
                    setSelectedListId(null);
                    setSelectedListName('');
                    setQuestions([]);
                }

                setSnackbarMessage('リストが削除されました');
            } catch (error) {
                setSnackbarMessage('リストの削除に失敗しました');
            }
            setSnackbarOpen(true);
        });
        setDialogOpen(true);
    };


    const fetchQuestionLists = useCallback(async () => {

        try {
            // Get the access token from Auth0
            const accessToken = await getAccessTokenSilently();
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/get_firebase_token`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,  // Authorizationヘッダーにトークンを追加
                }
            });
            const firebaseToken = response.data.firebase_token;
            await signInWithCustomToken(auth, firebaseToken);
            const currentUser = auth.currentUser;
            const userId = currentUser?.uid;
            const querySnapshot = await getDocs(collection(db, `users/${userId}/questionLists`));
            const lists = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as { id: string; name: string; questions: string[] }[];
            setQuestionLists(lists);
            if (lists.length === 0) {
                await handleDownloadDefaultListsIfNeeded();
            } else if (!selectedListId) {
                // まだリストが選択されていない場合は最初のリストを選択
                setSelectedListId(lists[0].id);
                setSelectedListName(lists[0].name);
                setQuestions(lists[0].questions || []);
            }
            
        } catch (error) {
            console.error('質問リストの取得に失敗しました', error);
        }
    }, [handleDownloadDefaultListsIfNeeded, selectedListId]);


    useEffect(() => {
        fetchQuestionLists();
    }, [isLoading, fetchQuestionLists, isAuthenticated, user]);

    useEffect(() => {
        if (editingIndex !== null && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editingIndex]);

    const handleOpenDownloadDialog = async () => {
        try {
            // Get the access token from Auth0
            const accessToken = await getAccessTokenSilently();

            // バックエンドのエンドポイントにアクセス
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/get_checklists`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,  // Authorizationヘッダーにトークンを追加
                }
            });

            if (response.status >= 200 && response.status < 300 && Array.isArray(response.data.checklist)) {
                // defaultListsはidとnameだけ保持
                const defaultListItems = response.data.checklist.map((item: { title: string }, index: number) => ({
                    id: `dummy-id-${index}`,
                    name: item.title,
                }));
                setDefaultLists(defaultListItems);

                // questionListsはnameとquestionsを保持
                const questionListItems = response.data.checklist.map((item: { title: string; checklist: string[] }, index: number) => ({
                    id: `dummy-id-${index}`,
                    name: item.title,
                    questions: item.checklist,  // 質問リストを格納
                }));
                setQuestionLists(questionListItems);

                setDefaultDownloadDialogOpen(true);
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error('デフォルト質問リストの取得に失敗しました', error);
            setSnackbarMessage('デフォルト質問リストの取得に失敗しました');
            setSnackbarOpen(true);
        }
    };

    const handleDefaultPromptDialogClose = async (confirmDownload: boolean) => {

        try {

            if (doNotShowAgain) {
                // Save user settings using the Auth0 user ID (user.sub)
                await saveUserSettings(user?.sub || '', { showDefaultPrompt: false });
            }

            setDefaultPromptDialogOpen(false);

            if (confirmDownload) {
                handleOpenDownloadDialog();
            }
        } catch (error) {
            console.error("Failed to save user settings:", error);
        }
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, listId: string) => {
        if (event.target.checked) {
            setSelectedDefaultLists(prev => [...prev, listId]);
        } else {
            setSelectedDefaultLists(prev => prev.filter(id => id !== listId));
        }
    };

    const handleDownloadSelectedLists = async () => {
        try {
            for (const listId of selectedDefaultLists) {
                // questionListsから選択されたリストを取得
                const selectedList = questionLists.find(list => list.id === listId);

                if (selectedList) {
                    // Firestoreにリストを保存
                    await addDoc(collection(db, `users/${user?.sub}/questionLists`), {
                        name: selectedList.name,        // タイトルを保存
                        questions: selectedList.questions, // 質問リストをquestionsとして保存
                        isDefault: true,               // デフォルトリストであることを示すフラグ
                    });
                }
            }

            setSnackbarMessage('選択されたデフォルトリストが正常にダウンロードされました。');
            setSnackbarOpen(true);
            setDefaultDownloadDialogOpen(false);
            setSelectedDefaultLists([]);
            await fetchQuestionLists(); // Firestoreに保存後にリストを再取得して状態を更新

        } catch (error) {
            setSnackbarMessage('デフォルトリストのダウンロードに失敗しました。');
            setSnackbarOpen(true);
        }
    };

    const handleListChange = (event: SelectChangeEvent<string | null>) => {
        const selectedId = event.target.value as string;
        setSelectedListId(selectedId);
        const selectedList = questionLists.find(list => list.id === selectedId);
        setSelectedListName(selectedList?.name || '');
        setQuestions(selectedList?.questions || []);
        setIsEdited(false);
    };

    const handleAddNewList = () => {
        setNewListDialogOpen(true);
    };

    const handleCreateNewList = async () => {

        if (newListName.trim()) {
            const docRef = await addDoc(collection(db, `users/${user?.sub}/questionLists`), {
                name: newListName.trim(),
                questions: [],
            });
            setQuestionLists([...questionLists, { id: docRef.id, name: newListName.trim(), questions: [] }]);
            setSelectedListId(docRef.id);
            setSelectedListName(newListName.trim());
            setQuestions([]);
            setSnackbarMessage('新しいリストが作成されました');
            setSnackbarOpen(true);
        }
        setNewListDialogOpen(false);
        setNewListName('');
    };

    const handleSaveQuestions = async () => {        
        if (selectedListId) {
            try {
                await updateDoc(doc(db, `users/${user?.sub}/questionLists/${selectedListId}`), {
                    questions,
                });

                setQuestionLists(prevLists =>
                    prevLists.map(list =>
                        list.id === selectedListId ? { ...list, questions } : list
                    )
                );
                setIsEdited(false);
                setSnackbarMessage('質問リストが保存されました');
            } catch (error) {
                setSnackbarMessage('質問リストの保存に失敗しました');
            }
            setSnackbarOpen(true);
        }
    };

    const handleDialogClose = (confirm: boolean) => {
        setDialogOpen(false);
        if (confirm) dialogAction();
    };

    if (!isAuthenticated || !user || !user.sub) {
        return <div>User is not authenticated</div>; // Render a fallback or error state
    }

    return (
        <Box display='flex' flexDirection='column' justifyContent='space-between' gap='16px'>
            {/* タイトル部分 */}
            <Box
                display="flex"
                justifyContent="space-between"
                flexDirection='row'
                alignItems="center"
                borderBottom={2}
                borderColor="#DAD4F7"
            >
                {/* 左側: アイコンと「質問リスト」 */}
                <Box display="flex" alignItems="center" gap='8px'>
                    <Box sx={{ width: '24px', height: '18px' }}>
                        <img src={listIcon} alt="リストアイコン" />
                    </Box>
                    <Typography
                        sx={{
                            color: '#232323',
                            fontSize: '24px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '33.6px', // 140% of font-size
                        }}
                    >
                        質問リスト
                    </Typography>
                </Box>

                {/* 右側: 質問リスト選択と新規作成 */}
                <Box display='flex' gap='8px' height='64px' alignSelf='stretch' alignItems='center'>
                    {/* 新しいリストを追加 ボタン
                    <Button
                        onClick={handleAddNewList}
                        variant="contained"
                        sx={{
                            display: 'flex',
                            padding: '8px 16px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px',
                            borderRadius: '4px',
                            background: '#8471E6',
                            '&:hover': {
                                background: '#9D8DEB',
                            },
                            '&:active': {
                                background: '#5F46DE'
                            }
                        }}
                    >
                        新しいリストを追加
                    </Button> */}

                    {/* 質問リストを選択 ボタン */}
                    <Button
                        onClick={handleOpenListChoice}
                        variant="contained"
                        sx={{
                            display: 'flex',
                            padding: '8px 16px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px',
                            borderRadius: '4px',
                            background: '#8471E6',
                            '&:hover': {
                                background: '#9D8DEB',
                            },
                            '&:active': {
                                background: '#5F46DE'
                            }
                        }}
                    >
                        質問リストを選択
                    </Button>
                    {/* 質問リストを選択するためのポップオーバー */}
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseListChoice}
                        sx={{ marginTop: 1 }}
                    >
                        {questionLists.length === 0 ? (
                            <MenuItem disabled>
                                質問リストがありません
                            </MenuItem>
                        ) : (
                            questionLists.map((list) => (
                                <MenuItem
                                    key={list.id}
                                    onClick={() => {
                                        // ID、リスト名、質問を反映させる
                                        setSelectedListId(list.id);  // 質問リストのIDを設定
                                        const selectedList = questionLists.find(qList => qList.id === list.id);
                                        setSelectedListName(selectedList?.name || '');  // リスト名を設定
                                        setQuestions(selectedList?.questions || []);  // 質問リストを設定
                                        setIsEdited(false);  // 編集状態をリセット
                                        handleCloseListChoice();  // メニューを閉じる
                                    }}
                                >
                                    <Box display="flex" alignItems="center">
                                        <Typography sx={{fontSize: "16px"}}>{list.name} ({list.questions?.length || 0})</Typography>
                                    </Box>
                                </MenuItem>
                            ))
                        )}
                    </Menu>


                    {/* デフォルトリストをダウンロード ボタン
                    <Button
                        onClick={handleOpenDownloadDialog}
                        variant="contained"
                        sx={{
                            display: 'flex',
                            padding: '8px 16px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px',
                            borderRadius: '4px',
                            border: '1px solid #5F46DE',
                            background: '#FFF',
                            color: '#5F46DE',
                            '&:hover': {
                                background: '#E5E3FA',
                            },
                            '&:active': {
                                background: '#DAD4F7'
                            }
                        }}
                    >
                        デフォルトリストをダウンロード
                    </Button> */}
                    {/* 新規リストを作成 ボタン */}
                    <Button
                        onClick={handleAddNewList}
                        variant="contained"
                        sx={{
                            display: 'flex',
                            padding: '8px 16px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px',
                            borderRadius: '4px',
                            border: '1px solid #5F46DE',
                            background: '#FFF',
                            color: '#5F46DE',
                            '&:hover': {
                                background: '#E5E3FA',
                            },
                            '&:active': {
                                background: '#DAD4F7'
                            }
                        }}
                    >
                        新規リストを作成
                    </Button>
                </Box>
            </Box>

            {/* 質問追加用フォームを含んだ、質問リスト自体の部分 */}
            {/* 質問リスト自体の部分 */}
            <Box>
                <Accordion defaultExpanded sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0px 0px',
                    background: 'transparent',
                    boxShadow: 'none',
                    gap: '16px'
                }}>
                    {/* 格納されている時に表示される部分 */}
                    <AccordionSummary
                        expandIcon={<img src={expandIcon} alt="Expand" />}
                        aria-controls="question-list-content"
                        id="question-list-header"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px', // expandIconとリストの名前の間のgap
                            flexDirection: 'row',
                            padding: '0px 0px', // 不要な余白を削除
                            justifyContent: 'flex-start',
                            width: 'fit-content'
                        }}
                    >
                        {/* 質問リストの名前 */}
                        <Box display="flex" alignItems="center" gap="8px" sx={{ background: 'transparent' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: '500', lineHeight: '22.4px', color: '#5F46DE' }}>
                                {selectedListName}
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: '500', lineHeight: '22.4px', color: '#5F46DE' }}>
                                ({questions.length} 項目)
                            </Typography>
                            <Typography>が選択されています</Typography>
                            {isEdited && (
                                <Typography variant="caption" color="textSecondary">
                                    (編集済み)
                                </Typography>
                            )}
                        </Box>
                    </AccordionSummary>
                    {/* 背景色の区切り用のBox */}
                    <Box sx={{
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '24px',
                        justifyContent: 'center',
                        gap: '16px',
                        alignSelf: 'stretch',
                        borderRadius: '8px',
                        border: '1px solid #DAD4F7'
                    }}>
                        {/* 要素数と、保存・削除ボタン */}
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Typography
                                sx={{
                                    fontSize: '20px',
                                    fontWeight: '500',
                                    lineHeight: '28px',
                                    color: '#5F46DE'
                                }}
                            >
                                ({questions.length})
                            </Typography>
                            {selectedListId && (
                                <Box display='flex' gap='8px'>
                                    <Button
                                        onClick={handleSaveQuestions}
                                        variant="contained"
                                        color="secondary"
                                        sx={{
                                            gap: '4px',
                                            borderRadius: '24px',
                                            background: '#5135DC',
                                            '&:hover': {
                                                background: '#5F46DE'
                                            },
                                            '&:active': {
                                                background: '#3A20B8'
                                            }
                                        }}
                                    >
                                        <AddIcon color='#FFF' width={16} height={16} />
                                        <Typography fontSize='14px' fontWeight={500} lineHeight='19.6px'>リストを保存</Typography>
                                    </Button>
                                    <Button
                                        onClick={() => handleDeleteList(selectedListId)}
                                        sx={{
                                            color: '#646464',
                                            borderRadius: "24px",
                                            border: "1px solid #646464",
                                            padding: "6px 12px",
                                            gap: "4px",
                                            "&:hover": {
                                                background: "#F1F1F1"
                                            },
                                            "&:active": {
                                                background: "#E6E6E6"
                                            }
                                        }}
                                    >
                                        <AddIcon color='#646464' width={16} height={16} />
                                        <Typography fontSize='14px' fontWeight={500} lineHeight='19.6px'>リストを削除</Typography>
                                    </Button>
                                </Box>
                            )}
                        </Box>
                        <Box display="flex" alignItems="center" gap="8px">
                        <TextField
                            label="質問を記入してください"
                            value={newQuestion}
                            onChange={(e) => onNewQuestionChange(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    onAddQuestion();
                                    setIsEdited(true);
                                }
                            }}
                            fullWidth
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                        </TextField>
                        <AddCircleButton onClick={() => {
                            onAddQuestion();
                            setIsEdited(true);
                        }}/>
                        {/* <IconButton
                            sx={{
                                padding: 0, // アイコンのサイズに合わせるため余白を除去
                                width: '48px', // TextFieldの高さに近いサイズを設定（必要に応じて調整）
                                height: '48px',
                                backgroundColor: '#FFF'
                                // "&:hover": {
                                //     background: "#F1F1F1"
                                // },
                                // "&:active": {
                                //     background: "#E6E6E6"
                                // }
                            }}
                            onClick={() => {
                                onAddQuestion();
                                setIsEdited(true);
                            }}>
                            <img src={addCircleIcon} alt="サークルアイコン"  style={{ transition: 'filter 0.3s ease' }} />
                        </IconButton> */}
                        </Box>
                        <AccordionDetails sx={{ padding: '0px 4px' }}>
                            <List>
                                {questions.map((question, index) => (
                                    <ListItem
                                        key={index}
                                        disableGutters
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                gap: '16px',
                                            }}
                                        >
                                            {/* 質問項目の通し番号 */}
                                            <Typography
                                                sx={{
                                                    fontSize: '16px',
                                                    fontWeight: 700,
                                                    lineHeight: '22.4px',
                                                    color: '#646464',
                                                }}
                                            >
                                                {index+1}
                                            </Typography>
                                            {/* この質問を編集中の場合 */}
                                            {editingIndex === index ? (
                                                <TextField
                                                    inputRef={inputRef}
                                                    fullWidth
                                                    value={question}
                                                    onChange={(e) => {
                                                        onQuestionChange(index, e.target.value);
                                                        setIsEdited(true);
                                                    }}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            onSaveQuestion(index);
                                                            setIsEdited(true);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                // この質問が編集でない場合
                                                <ListItemText primary={question} />
                                            )}
                                        </Box>
                                        {/* 編集中の場合は保存するボタン、そうでない場合は編集・削除のボタン */}
                                        <Box display='flex' flexDirection='row'>
                                            {editingIndex === index ? (
                                                <IconButton edge="end" aria-label="save" onClick={() => {
                                                    onSaveQuestion(index);
                                                    setIsEdited(true);
                                                }}>
                                                    <CheckIcon />
                                                </IconButton>
                                            ) : (
                                                <>
                                                    <IconButton edge="end" aria-label="edit" onClick={() => onEditQuestion(index)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton edge="end" aria-label="delete" onClick={() => {
                                                        onDeleteQuestion(index);
                                                        setIsEdited(true);
                                                    }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                            )}
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                        </AccordionDetails>
                    </Box>
                </Accordion>
            </Box>

            {/* ダイアログやスナックバー */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
            <Dialog
                open={dialogOpen}
                onClose={() => handleDialogClose(false)}
            >
                <DialogTitle>リストを削除しますか？</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        この操作は元に戻せません。リストを削除すると、関連する質問もすべて削除されます。
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(false)} color="primary">
                        キャンセル
                    </Button>
                    <Button onClick={() => handleDialogClose(true)} color="secondary">
                        削除
                    </Button>
                </DialogActions>
            </Dialog>

            {/* 新規リスト作成ダイアログ */}
            <Dialog
                open={newListDialogOpen}
                onClose={() => setNewListDialogOpen(false)}
                sx={{
                    '& .MuiDialog-paper': {
                        width: '100%', // ダイアログ全体の幅を1.5倍に設定
                        maxWidth: 'sm', // 最大幅を無効にして全体を広げる
                    },
                }}
            >
                <DialogTitle>新しい質問リストを作成</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="リスト名"
                        fullWidth
                        value={newListName}
                        onChange={(e) => setNewListName(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleCreateNewList();  // エンターキーでリスト作成
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNewListDialogOpen(false)} color="primary">
                        キャンセル
                    </Button>
                    <Button onClick={handleCreateNewList} color="primary">
                        作成
                    </Button>
                </DialogActions>
            </Dialog>

            {/* デフォルトリストダウンロードダイアログ */}
            <Dialog
                open={defaultDownloadDialogOpen}
                onClose={() => setDefaultDownloadDialogOpen(false)}
            >
                <DialogTitle>ダウンロードするリストを選択</DialogTitle>
                <DialogContent>
                    <List>
                        {defaultLists.map(list => (
                            <ListItem key={list.id} disableGutters>
                                <Checkbox
                                    checked={selectedDefaultLists.includes(list.id)}
                                    onChange={(event) => handleCheckboxChange(event, list.id)}
                                />
                                <ListItemText primary={list.name} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDefaultDownloadDialogOpen(false)} color="primary">
                        キャンセル
                    </Button>
                    <Button onClick={handleDownloadSelectedLists} color="primary" disabled={selectedDefaultLists.length === 0}>
                        ダウンロード
                    </Button>
                </DialogActions>
            </Dialog>

            {/* デフォルトリストダウンロードプロンプト */}
            <Dialog
                open={defaultPromptDialogOpen}
                onClose={() => handleDefaultPromptDialogClose(false)}
            >
                <DialogTitle>デフォルトリストをダウンロードしますか？</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        現在、質問リストが存在しません。デフォルトの質問リストをダウンロードしますか？
                    </DialogContentText>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={doNotShowAgain}
                                onChange={(e) => setDoNotShowAgain(e.target.checked)}
                            />
                        }
                        label="今後この質問を表示しない"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDefaultPromptDialogClose(false)} color="primary">
                        いいえ
                    </Button>
                    <Button onClick={() => handleDefaultPromptDialogClose(true)} color="primary">
                        はい
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default QuestionList;
